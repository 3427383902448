.centered {
    margin: auto;
}

.centerContent {
    text-align: center;
}

.bold {
    font-weight: bold;
}

.justify {
    text-align: justify;
}

.padding {
    padding: 30px;
}

#section10 h1 {
    font-family: "Raleway", sans-serif;
    font-size: 26px;
    margin: 0;
}

#section10 h2 {
    font-family: "Raleway", sans-serif;
    font-size: 30px;
    margin: 0;
    font-weight: bold;
}

#section10 h3 {
    font-family: "Raleway", sans-serif;
    font-size: 19px;
    margin: 0;
}

#section10 h2:before, #section10 h2:after {
    content: "";
    display: inline-block;
    width: 30%;
    margin: 0 .5em 0 -25%;
    vertical-align: middle;
    border-bottom: 7px solid;
}

#section10 h2:after {
    margin: 0 -25% 0 .5em;
}

#section10 p {
    font-family: "Raleway", sans-serif;
    font-size: 17px;
}

#section10 img {
    width: 90%;
}
